import React from "react"
import { motion } from 'framer-motion'
import { fadeBump, fade } from "../helpers/transitionHelper"
import { injectIntl } from "gatsby-plugin-intl-v3"
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import SEO from "../components/seo"

const CompositionsPage = ({ data, intl }) => {
  const { title, details  } = data.contentfulCompositions;
  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "composition" })}
        image={getSrc(data.contentfulMainPage.horizontal[4])}
      />
      <motion.section
        initial="initial"
        animate="enter"
        exit="exit"
        className="pb-24 md:pb-32 overflow-hidden relative"
        data-scroll-section
      >
        <div className="w-full">
          <div className="relative">
            <div className="w-full md:w-10/12 md:pl-24 lg:w-10/12 lg:pl-16 xl:pl-32 xl:w-11/12 md:ml-auto relative">
              <motion.div variants={fade} className="relative overflow-hidden">
                  <GatsbyImage
                    className="w-full h-full object-cover object-center image-scale-in"
                    image={data.contentfulMainPage.horizontal[4].gatsbyImageData}
                    alt={data.contentfulMainPage.horizontal[4].title} />
              </motion.div>

              <motion.div variants={fadeBump} className="w-full md:w-8/12 xl:w-8/12 mr-auto mb-10 md:mb-5vw xl:mb-4vw px-4 md:px-0 text-left">
                <h1 className="text-10xlvw md:text-5xlvw font-display relative leading-none border-b border-transparent tracking-tighter mb-3 pb-0 uppercase mt-3 md:mt-5">
                  {title}
                </h1>
              </motion.div>
            </div>


            <motion.div variants={fade} className="px-4 md:px-12">

                      <div className="w-10/12 md:w-10/12 md:pl-16 lg:w-10/12 lg:pl-8 xl:pl-20 xl:w-11/12 md:ml-auto relative mb-8 md:mb-12 xl:mb-16">
                        <div className="w-full md:w-9/12 xl:pl-2">
                          <div className="content max-w-xl">
                            {renderRichText(details)}
                          </div>
                        </div>
                      </div>

            </motion.div>
          </div>
        </div>
      </motion.section>
    </>
  )
}

export default injectIntl(CompositionsPage)

export const query = graphql`
    query ($language: String) {
        contentfulCompositions(
            contentful_id: {eq: "KuvAuvOe2db3rgsgBWcWu"}
            node_locale: {eq: $language}
        ) {
            title
            details {
                raw
            }
        }
        contentfulMainPage(
            contentful_id: { eq: "3IemKmfKhhIl4iKQaLPfme" }
            node_locale: { eq: $language }
        ) {
            horizontal {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 1650, height: 800)
            }
        }
    }
`